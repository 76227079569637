<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Tipo de Cambio - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
              <b-col sm="12" md="8"></b-col>
             
              <b-col sm="6" md="3">
                <b-input-group>
                  <b-form-input type="search" v-model="search" class="form-control" ></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" @click="ListExchangeRate">
                      <b-icon icon="search"></b-icon
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
               <b-col sm="6" md="1">
                <b-link v-if="Permission('ExchangeRateAdd')" class="btn form-control btn-primary" @click="ModalExchangeRateAddShow"><i class="fas fa-file"></i></b-link >
              </b-col>
            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="5%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="20%" class="text-center">Dólares</th>
                    <th width="20%" class="text-center">Pesos Chilenos</th>
                    <th width="10%" class="text-center">Usuario</th>
                    <th width="10%" class="text-center">Estado</th>
                    <th width="10%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.date }}</td>
                    <td class="text-right"> {{ item.usd }}</td>
                    <td class="text-right"> {{ item.clp }}</td>
                    <td class="text-left"> {{ item.user }}</td>
                     <td class="text-center">
                      <b-badge v-if="item.state == 1" variant="success">Activo</b-badge>
                      <b-badge v-if="item.state == 0" variant="warning">Inactivo</b-badge>
                    </td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('ExchangeRateEdit')" @click="ModalExchangeRateEditShow(item.id_exchange_rate)" >Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('ExchangeRateDelete')"  @click="ConfirmDeleteExchangeRate(item.id_exchange_rate)" >Eliminar</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage"  v-on:input="ListExchangeRate" :total-rows="rows" :per-page="perPage"  align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalExchangeRateAdd />
    <ModalExchangeRateEdit />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";
import ModalExchangeRateAdd from './components/ModalExchangeRateAdd'
import ModalExchangeRateEdit from './components/ModalExchangeRateEdit'
export default {
  name: "UsuarioList",
  components:{
    ModalExchangeRateAdd,
    ModalExchangeRateEdit,
  },
  data() {
    return {
      module:'ExchangeRate',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      search: "",
      data_table: [],
    };
  },
  mounted() {
    EventBus.$on('ListExchangeRate', () => {
      this.ListExchangeRate();
    });
    this.ListExchangeRate();
  },
  methods: {
    ListExchangeRate,
    EditCategory,
    ViewCategory,
    ConfirmDeleteExchangeRate,
    DeleteExchangeRate,
    Permission,

    ModalExchangeRateAddShow,
    ModalExchangeRateEditShow,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ListExchangeRate() {
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "exchange-rate/list/" + search + "?page=" + this.currentPage;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module: this.module, role: 1, },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}


function ModalExchangeRateAddShow() {
    EventBus.$emit('ModalExchangeRateAddShow');
}

function ModalExchangeRateEditShow(id_exchange_rate) {
    EventBus.$emit('ModalExchangeRateEditShow',id_exchange_rate);
}


function EditCategory(id_exchange_rate) {
  this.$router.push({
    name: "CategoryProductEdit",
    params: { id_exchange_rate: je.encrypt(parseInt(id_exchange_rate)) },
  });
}

function ViewCategory(id_exchange_rate) {
  this.$router.push({
    name: "CategoryProductView",
    params: { id_exchange_rate: je.encrypt(parseInt(id_exchange_rate)) },
  });
}

// Confirmar eliminar
function ConfirmDeleteExchangeRate(id_exchange_rate) {
  Swal.fire({
    title: "Esta seguro de eliminar el tipo de cambio ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteExchangeRate(id_exchange_rate);
    }
  });
}

// eliminar usuario
function DeleteExchangeRate(id_exchange_rate) {
  let me = this;
  let url = this.url_base + "exchange-rate/delete/" + id_exchange_rate;
  axios({
    method: "delete",
    url: url,
    headers: {token: this.token,module: this.module,role: 4},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_exchange_rate).indexOf(response.data.result.id_exchange_rate);
        me.data_table.splice(index, 1);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
